<template>
  <div class="card-content">
    <p class="field">
      <label class="is-size-7 has-text-weight-bold">
        <span class="icon is-small">
          <i class="fal fa-paint-brush" />
        </span>
        Colour |
        <a class="has-text-grey-lighter" @click="clearFilters('colors')">
          Reset
        </a>
      </label>
    </p>
    <div class="columns is-multiline">
      <div
        v-for="color in filterOptions['color']"
        :key="color"
        class="column radio-tick"
      >
        <checkbox name="colors" v-model="colors" :value="color">
          <span class="is-capitalized">{{ color }}</span>
        </checkbox>
      </div>
    </div>
    <hr />
    <p class="field">
      <label class="is-size-7 has-text-weight-bold">
        <span class="icon is-small">
          <i class="fal fa-tachometer-alt-fastest" />
        </span>
        Mileage |
        <a class="has-text-grey-lighter" @click="setMileage()">Reset</a>
      </label>
    </p>
    <div class="columns is-centered is-multiline">
      <div class="column is-10">
        <p class="is-size-3">
          {{ formatNumber(mileage[0]) }} to {{ formatNumber(mileage[1]) }}
        </p>
        <p class="is-size-7">Drag the sliders to choose a range</p>
      </div>
      <div class="column is-8 has-text-centered">
        <VueSlider
          class="slider"
          dot-size="33"
          :min="filterOptions.mileage.min"
          :max="filterOptions.mileage.max"
          :interval="1000"
          :enable-cross="false"
          v-model="mileage"
        />
      </div>
    </div>
    <hr />
    <p class="field">
      <label class="is-size-7 has-text-weight-bold">
        <span class="icon is-small">
          <i class="fal fa-list" />
        </span>
        Advert text search |
        <a class="has-text-grey-lighter" @click="clearFilters('adText')">
          Reset
        </a>
      </label>
    </p>
    <label for="adText" class="label is-size-7">Keyword search</label>
    <input
      v-bind="adText"
      type="text"
      placeholder="Parking sensor"
      class="input is-small"
    />
    <div class="help">
      To search for multiple terms, use a comma delimited list:
      <span class="has-text-danger">xenon,leather</span>
    </div>
    <hr />
    <p class="field">
      <label class="is-size-7 has-text-weight-bold">
        <span class="icon is-small">
          <i class="fal fa-calendar-alt" />
        </span>
        Year |
        <a class="has-text-grey-lighter" @click="clearFilters('years')">
          Reset
        </a>
      </label>
    </p>
    <div
      v-for="year in filterOptions['year']"
      :key="year"
      class="column radio-tick"
    >
      <checkbox name="colors" v-model="years" :value="year">
        <span>{{ year }}</span>
      </checkbox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
import 'vue-slider-component/theme/antd.css'
export default {
  name: 'StockviewMoreFilter',
  // components: { VueSlider: () => import('vue-slider-component') },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    colors: [],
    mileage: [],
    adText: '',
    years: []
  }),
  watch: {
    colors: {
      handler() {
        this.updateFilters({ type: 'color', payload: this.colors.join(',') })
      }
    },
    mileage: {
      handler() {
        this.updateFilters({ type: 'mileage', payload: this.mileage.join(',') })
      }
    },
    adText: {
      handler() {
        this.updateFilters({ type: 'adText', payload: this.adText })
      }
    },
    years: {
      handler() {
        this.updateFilters({ type: 'year', payload: this.years.join(',') })
      }
    }
  },
  mounted() {
    this.setMileage()
    if (this.filters['color']) this.colors = this.filters['color'].split(',')
    if (this.filters['year']) this.years = this.filters['year'].split(',')
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters', 'filterOptions']),
    itemCount() {
      return false
    }
  },
  methods: {
    formatNumber,
    ...mapActions('stockview/listings', ['updateFilters']),
    setMileage() {
      const { min } = this.filterOptions.mileage
      const { max } = this.filterOptions.mileage
      this.mileage = [min, max]
    },
    clearFilters(type) {
      if (type === 'adText') this[type] = ''
      else this[type] = []
    }
  }
}
</script>
